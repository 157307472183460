// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-en-tsx": () => import("./../../../src/pages/404/index.en.tsx" /* webpackChunkName: "component---src-pages-404-index-en-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-404-index-zh-cn-tsx": () => import("./../../../src/pages/404/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-404-index-zh-cn-tsx" */),
  "component---src-pages-about-index-en-tsx": () => import("./../../../src/pages/about/index.en.tsx" /* webpackChunkName: "component---src-pages-about-index-en-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-index-zh-cn-tsx": () => import("./../../../src/pages/about/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-about-index-zh-cn-tsx" */),
  "component---src-pages-contact-index-en-tsx": () => import("./../../../src/pages/contact/index.en.tsx" /* webpackChunkName: "component---src-pages-contact-index-en-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-index-zh-cn-tsx": () => import("./../../../src/pages/contact/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-contact-index-zh-cn-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-index-zh-cn-tsx": () => import("./../../../src/pages/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-index-zh-cn-tsx" */),
  "component---src-pages-join-index-en-tsx": () => import("./../../../src/pages/join/index.en.tsx" /* webpackChunkName: "component---src-pages-join-index-en-tsx" */),
  "component---src-pages-join-index-tsx": () => import("./../../../src/pages/join/index.tsx" /* webpackChunkName: "component---src-pages-join-index-tsx" */),
  "component---src-pages-join-index-zh-cn-tsx": () => import("./../../../src/pages/join/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-join-index-zh-cn-tsx" */),
  "component---src-pages-price-index-en-tsx": () => import("./../../../src/pages/price/index.en.tsx" /* webpackChunkName: "component---src-pages-price-index-en-tsx" */),
  "component---src-pages-price-index-tsx": () => import("./../../../src/pages/price/index.tsx" /* webpackChunkName: "component---src-pages-price-index-tsx" */),
  "component---src-pages-price-index-zh-cn-tsx": () => import("./../../../src/pages/price/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-price-index-zh-cn-tsx" */),
  "component---src-pages-privacy-index-en-tsx": () => import("./../../../src/pages/privacy/index.en.tsx" /* webpackChunkName: "component---src-pages-privacy-index-en-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-privacy-index-zh-cn-tsx": () => import("./../../../src/pages/privacy/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-privacy-index-zh-cn-tsx" */),
  "component---src-pages-product-index-en-tsx": () => import("./../../../src/pages/product/index.en.tsx" /* webpackChunkName: "component---src-pages-product-index-en-tsx" */),
  "component---src-pages-product-index-tsx": () => import("./../../../src/pages/product/index.tsx" /* webpackChunkName: "component---src-pages-product-index-tsx" */),
  "component---src-pages-product-index-zh-cn-tsx": () => import("./../../../src/pages/product/index.zh-CN.tsx" /* webpackChunkName: "component---src-pages-product-index-zh-cn-tsx" */)
}

